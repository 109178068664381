/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to true if you want to use the splash screen.
  useCustomCursor: false, // Change this to false if you want the good'ol cursor
  googleTrackingID: "G-Q73DG3T9K7",
};

//Home Page
const greeting = {
  title: "Hello World.",
  title2: "Suhas K C",
  logo_name: "suhas.kc",
  nickname: "suhas / thegame",
  full_name: "Suhas Katari Chaluva Kumar",
  subTitle:
    "Software Engineer, AIML, FinTech Enthusiast 🔥. Always learning and growing",
  resumeLink:
    "https://drive.google.com/file/d/1XeBd0AP9i54NS1lMnc_eWNBF3CglexCq/view?usp=share_link",
  mail: "mailto:suhaskatari1998@gmail.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/suhasthegame",
  linkedin: "https://www.linkedin.com/in/suhas-k-c/",
  gmail: "suhaskatari1998@gmail.com",
};

const skills = {
  data: [
    {
      title: "Software Engineer",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building highly interactive User Interfaces by leveraging modern technologies like React.js, Redux",
        "⚡ Developing full-stack applciations using the MERN(Mongo,Express,Node,React) stack",
        "⚡ Optimizing AIML algorothms to leverage GPU power, code refactoring of large codebases, and integrate AIML algorithms into user applications",
        "⚡ Developing API's and Microservices that are reusable and scalable",
        "⚡ MMA, Ultimate Frisbee, Gym and Coffee keeps me going 😉",
      ],
      softwareSkills: [
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#3776AB",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "logos:nodejs",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "GoLang",
          fontAwesomeClassname: "fa6-brands:golang",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Swift",
          fontAwesomeClassname: "simple-icons:swift",
          style: {
            color: "#DE33A6",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#439743",
          },
        },
        {
          skillName: "PostGreSQL",
          fontAwesomeClassname: "logos:postgresql",
          style: {
            color: "#4479A1",
          },
        },
        {
          skillName: "Jenkins",
          fontAwesomeClassname: "logos:jenkins",
          style: {
            color: "#3DDC84",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#7377AD",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "API Development",
          fontAwesomeClassname: "eos-icons:api",
          style: {
            color: "#f89820",
          },
        },
        {
          skillName: "DevSecOps",
          fontAwesomeClassname: "devicon:azuredevops",
          style: {
            color: "#02569B",
          },
        },
        {
          skillName: "UI/UX ",
          fontAwesomeClassname: "arcticons:one-ui-icon-pack",
          style: {
            color: "#29B0EE",
          },
        },
        {
          skillName: "IT Automation",
          fontAwesomeClassname: "vaadin:automation",
          style: {
            color: "#029FCE",
          },
        },

        {
          skillName: "Software Testing",
          fontAwesomeClassname: "logos:testing-library",
          style: {
            color: "#0865A6",
          },
        },
        {
          skillName: "Flask",
          fontAwesomeClassname: "logos:flask",
          style: {
            color: "#207297",
          },
        },
        {
          skillName: "App Development",
          fontAwesomeClassname: "logos:web-dev-icon",
          style: {
            color: "#CA1A22",
          },
        },
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },

        {
          skillName: "Linux",
          fontAwesomeClassname: "skill-icons:linux-light",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "Redux",
          fontAwesomeClassname: "skill-icons:redux",
          style: {
            color: "#E94E32",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms such as AWS and Azure",
        "⚡ Experience hosting and managing website",
        "⚡ AWS - Lambda, S3, EC2, Cloudfront, RDS. Azure - Functions, Active Directory, Policies, DevOps",
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "devicon:azure-wordmark",
          style: {
            color: "#38AFBB",
          },
        },
        {
          skillName: "Heroku",
          fontAwesomeClassname: "simple-icons:heroku",
          style: {
            color: "#6863A6",
          },
        },
        {
          skillName: "Google Cloud Platform",
          fontAwesomeClassname: "devicon:googlecloud",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "vscode-icons:file-type-dockertest",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Netlify",
          fontAwesomeClassname: "logos:netlify-icon",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "GitHub Actions",
          fontAwesomeClassname: "simple-icons:githubactions",
          style: {
            color: "#5b77ef",
          },
        },
        {
          skillName: "Maven",
          fontAwesomeClassname: "devicon:maven",
          style: {
            color: "#336791",
          },
        },
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "University of Florida",
      subtitle: "Master of Science in Computer Science and Engineering",
      logo_path: "UF-logo.png",
      alt_name: "University of Florida",
      duration: "2022 - 2024",
      descriptions: [
        "⚡ I'm currently pursuing my Masters in Computer Science expecting to graduate in Spring 2024.",
        "⚡ Completed Coursework include Applied Machine Learning, Analysis of Algorithms, Advanced Data Structures, Human-Computer Interaction, Information Visualization, Advanced Computer Networks, Advanced Databases, and Complex Analysis",
        "⚡ Current Cummulative GPA - 3.66/4",
      ],
      website_link: "https://www.ufl.edu",
    },
    {
      title: "REVA University",
      subtitle: "Bachelors in Computer Science and Engineering",
      logo_path: "reva-logo.png",
      alt_name: "REVA University",
      duration: "2016 - 2020",
      descriptions: [
        "⚡ Completed Coursework include core subjects like Data Structures, DBMS, Networking, Security, etc.",
        "⚡ I have implemented several projects such as the AR Car Showroom app, RESA seating app, and Food Ordering System Application",
        "⚡ I have secured 9.33/10 CGPA.",
        "⚡ Was awarded the Best Outgoing student",
      ],
      website_link: "https://www.reva.edu.in/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Microsot Technology Associate: Cloud Fundamentals",
      subtitle: "Microsoft",
      logo_path: "mta.png",
      certificate_link:
        "https://drive.google.com/file/d/1e7tDg5oVPjHW3botijAfgbh6YugkfyBx/view?usp=sharing",
      alt_name: "Microsoft",
      color_code: "#2B7CD3",
    },
    {
      title: "Programming woth Python",
      subtitle: "Internshala",
      logo_path: "py.png",
      certificate_link:
        "https://drive.google.com/file/d/1GloDK0nAHvZ4W4pelVTYTB7J0wOsXdrQ/view?usp=sharing",
      alt_name: "Python Certification",
      color_code: "#E2405F",
    },
    {
      title: "Full Stack Web Development",
      subtitle: "Internshala",
      logo_path: "web_dev.png",
      certificate_link:
        "https://drive.google.com/file/d/1Uf0SrGPUadJorT5R6pvNI9PLNI8ndmty/view?usp=sharing",
      alt_name: "Web development certification",
      // color_code: "#F6B808",
      color_code: "#47A048",
    },
    {
      title: "Unity AR and Cloud Based AI application",
      subtitle: "Udemy",
      logo_path: "unity.png",
      certificate_link:
        "https://drive.google.com/file/d/1uONI857Vm94e0zY_rKOtI9VBt0XDu_kJ/view?usp=sharing",
      alt_name: "Unity AR development",
      color_code: "#2AAFED",
    },
    {
      title: "Hackfest 2019",
      subtitle: "National Level Hackathon",
      logo_path: "hackfest.png",
      certificate_link:
        "https://drive.google.com/file/d/1M-J26U3dNvL9R-bbc-8EpfNEejG22Rln/view?usp=sharing",
      alt_name: "Hackfest National level Hackathon",
      color_code: "#fe0037",
    },
    {
      title: "Cyber Space and Security",
      subtitle: "Workshop",
      logo_path: "sec.png",
      certificate_link:
        "https://drive.google.com/file/d/1rBBpIA_7wJ76lByffbipNmCv3R5O1oEV/view?usp=sharing",
      alt_name: "Cyber Security workshop",
      color_code: "#2AAFED",
    },
    {
      title: "Power Bi Cetification",
      subtitle: "Coursera",
      logo_path: "powerbi.png",
      certificate_link: "",
      alt_name: "PowerBI certification",

      color_code: "#fffbf3",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "Ex-Amazonian | 2+ years of professional experience Software and Data Engineering and AI/ML algorithm optimization",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work Experience",
      experiences: [
        {
          title: "Software Engineer - Graduate Research Assistant",
          company: "University of Florida, College of Medicine",
          company_url: "https://cmilab.nephrology.medicine.ufl.edu/",
          logo_path: "ufcom.png",
          duration: "Jan 2023 - Present",
          location: "Gainesville, Florida",
          description: `• Utilized Python to adapt a Docker-based Flask DSA application for Singularity and HPC compatibility, enabling the use of HiperGator’s power.\n
          • Optimized AI/ML algorithms for better NVIDIA GPU utilization, resulting in 50% more efficiency when running AI/ML algorithms on Whole Slide Images.\n
          • Worked on NaviGator application development using Python and delivered it to users 3 weeks ahead of schedule, applying principles of SDLC, enabling usage by AI researchers at Harvard, Yale, and Johns Hopkins, furthering AI research.\n
          `,
          color: "#ee3c26",
        },
        {
          title: "Platform Engineer Intern",
          company: "Steelcase Inc",
          company_url: "https://www.steelcase.com/",
          logo_path: "steelcase.png",
          duration: "May 2023 - Aug 2023",
          location: "Grand Rapids, Michigan",
          description: `• Analyzed Azure cloud cost data using Numpy and Pandas, leading to the identification and elimination of non-functional servers, saving $20,000 per month.\n
          • Worked on Azure Functions and security policies, automating platform-related tasks and enhancing operational efficiency by eliminating redundant operations and bolstering security measures.\n
          • Conducted a usability study for prototyped PowerBI dashboards, gathering user feedback to optimize report visibility and relevance, enhancing decision-making processes across IT teams\n
          `,
          color: "#ee3c26",
        },
        {
          title: "Full Stack Software Engineer",
          company: "Polaris Wireless",
          company_url: "https://www.polariswireless.com/",
          logo_path: "pl.png",
          duration: "Dec 2021 - Jul 2022",
          location: "Bengaluru, India",
          description: ` Worked on the transition of a major portion of a large-scale codebase from class-based React and Redux to function-based React, resulting in a 30% reduction in codebase size and a 40% increase in system performance, while enhancing functionality and security.\n
          • Engaged in sprint planning, UX reviews, and feature specification meetings, providing insights to enhance user requirement anticipation and application usability, while collaborating with backend and testing teams to swiftly resolve user-facing bugs, ensuring a seamless user experience.
          `,
          color: "#0071C5",
        },
        {
          title: "Data Engineer",
          company: "Amazon",
          company_url: "https://www.amazon.com/",
          logo_path: "amazon.png",
          duration: "Sep 2020 - Jul 2021",
          location: "Bengaluru, India",
          description: `• Developed Python scripts and Java snippets for collecting data from competitor websites across various marketplaces. Processed and analyzed the collected data, effectively utilizing SQL and Bash scripting for database operations.\n
          • Worked with several AWS services including S3, Lambda, RDS, and EC2 to manage, store, and process collected data, leveraging AWS’s technologies for operational efficiency.          
          `,
          color: "#0071C5",
        },
      ],
    },
    {
      title: "Volunteering and Academic Clubs",
      experiences: [
        {
          title: "Navigator",
          company: "University of Florida",
          company_url: "https://www.ufnavigatorsinternational.com/",
          logo_path: "navi.png",
          duration: "Sep 2022 - Present",
          location: "Gaineville, Florida",
          description:
            "Help international studnets navigate their way around the US.",
          color: "#040f26",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description: `I love working on projects, either school project or personal project. So, you'll see a wide range of assorted projects here ranging from 
    web development, Information Visualization, machine learning, Data Base application and more...
    `,
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "suhas.png",
    description:
      "I'm actively looking for roles post graduation in Spring 2024. If you like my portfolio and think you have a role for me or just want to connect, please feel free to contact me and I'll get back to you as soon as possible",
  },
};

const projects = {
  data: [
    {
      id: "0",
      name: "CARDv",
      url: "https://cardv-suhasthegame46-gmailcom.vercel.app/",
      description:
        "Cancer and Ancestry Relationship Data Visualization - This application allows us to see how different cancers genes impact different races in a unique way. For example, African-American women are 3x more susceptible to breast cancer.",
      languages: [
        {
          name: "ReactJS",
          iconifyClass: "devicon:react-wordmark",
        },
        {
          name: "ChartJS",
          iconifyClass: "file-icons:chartjs",
        },
        {
          name: "Python",
          iconifyClass: "devicon:python-wordmark",
        },
        {
          name: "machine Learning",
          iconifyClass: "carbon:machine-learning-modelr",
        },
      ],
      links: [
        {
          name: "Github",
          url: "https://github.com/suhasthegame/cardv",
          iconifyClass: "ri:github-fill",
        },
        {
          name: "Live Demo",
          url: "https://cardv-suhasthegame46-gmailcom.vercel.app/",
          iconifyClass: "mdi:web",
        },
      ],
    },
    {
      id: "1",
      name: "Crime Data Analysis",
      url: "https://github.com/suhasthegame/CrimeDataAnalysis",
      description:
        "A simple application developed using real FBI crime data that showcases the power of SQL and Visualization. (Has more than 5M records) This app can show various crime statistics!",
      languages: [
        {
          name: "OracleSQL",
          iconifyClass: "logos:oracle",
        },
        {
          name: "NodeJs",
          iconifyClass: "logos-nodejs",
        },
        {
          name: "ReactJS",
          iconifyClass: "devicon:react-wordmark",
        },
      ],
      links: [
        {
          name: "Github",
          url: "https://github.com/suhasthegame/CrimeDataAnalysis",
          iconifyClass: "ri:github-fill",
        },
      ],
    },
    {
      id: "2",
      name: "Food Ordering System using IoT enabled RFID Reader.",
      url: "http://www.testmagzine.biz/index.php/testmagzine/article/view/8062",
      description:
        "An full-fledged application that leverages IoT and Kiosk Machines to solve the problem associated with food ordering system on REVA university Campus",
      languages: [
        {
          name: "ReactJS",
          iconifyClass: "logos-react",
        },
        {
          name: "Python Flask ",
          iconifyClass: "logos:flask",
        },
        {
          name: "MongoDB",
          iconifyClass: "devicon:mongodb-wordmark",
        },
        {
          name: "C++",
          iconifyClass: "bxl:c-plus-plus",
        },
      ],
      links: [
        {
          name: "Research Publication",
          url:
            "http://www.testmagzine.biz/index.php/testmagzine/article/view/8062",
          iconifyClass: "fluent:window-dev-edit-20-filled",
        },
      ],
    },
    {
      id: "3",
      name: "AR CarShowroom App",
      url: "",
      description:
        "A cross-platform AR car showroom app that leverages AI and allows you to view car models in AR. Bonus, has voice commands to perform basic functions",
      languages: [
        {
          name: "Unity",
          iconifyClass: "skill-icons:unity-dark",
        },
        {
          name: "C#",
          iconifyClass: "logos:c-sharp",
        },
        {
          name: "Wit.AI",
          iconifyClass: "vscode-icons:file-type-ai",
        },
      ],
      links: [],
    },
    {
      id: "4",
      name: "LinkedIn Clone",
      url: "https://github.com/suhasthegame/LinkedIn-Clone",
      description: "A LinkedIn clone application (becuase why not??)",
      languages: [
        {
          name: "ReactJS",
          iconifyClass: "devicon:react-wordmark",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "HTML5",
          iconifyClass: "logos-html-5",
        },
        {
          name: "CSS3",
          iconifyClass: "logos-css-3",
        },
      ],
      links: [
        {
          name: "Github",
          url: "https://github.com/suhasthegame/LinkedIn-Clone",
          iconifyClass: "ri:github-fill",
        },
      ],
    },
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
